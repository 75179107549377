@tailwind base;
@tailwind components;
@tailwind utilities;

body{
  background-color: #323232;
}

/* CSS file */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in-up {
  animation: fadeInUp 1s ease-out;
}

[type='text']:focus,
input:where(:not([type])):focus,
/* ...other selectors... */
select:focus {
    --tw-ring-color: transparent; /* Change ring color to orange */
    --tw-ring-width: 0; /* Remove ring */
    /* ...other styles... */
}